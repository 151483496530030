<template>
  <tr>
    <th class="text-center">
      <h6 class="mt-2">{{ number + 1 }}</h6>
    </th>
    <td>
      <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
        <b-form-input
          class="rounded-0"
          ref="baslik"
          v-model="item.icerik[defaultDil].baslik"
          :state="getValidationState(validationContext)"
          aria-describedby="input-1-live-feedback"
          placeholder="Başlık"
        />
      </validation-provider>
    </td>
    <td>
      <b-form-input
        class="rounded-0"
        ref="baslik"
        v-model="item.ozel_alan"
        placeholder="Özel Alan"
        aria-describedby="input-1-live-feedback"
      />
    </td>
    <td>
      <b-button class="rounded-0" variant="outline-danger" @click="$emit('remove', number)">
        <i class="fad fa-trash" />
      </b-button>
    </td>
  </tr>
</template>

<script>
import store from '@/store';
import { ref, defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    number: [Number],
    item: { type: Object, required: true },
  },
  setup() {
    const expo = {};
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
